@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-icons, .right-icons {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 25px
}
.left-icons {
  justify-content: flex-start;
}
.right-icons {
  justify-content: flex-end;
}
.left-icons i, .right-icons i {
  margin-right: 4px;
}
.title {
  flex-grow: 1;
  text-align: center;
}
.media-container {
  width: 85%;
  height: 'auto';
  border-radius: 20px;
  background-color: #fff;
  opacity: 0.97;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}
.pjul {
  display: flex;
  justify-content: center;
}

.desctext {
  padding-left: 70px;
  padding-right: 70px;
  text-align: justify;
  text-justify: inter-word;
  white-space: pre-line;
}

.pjul ul,
.pjli {
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
}

.pjli:hover {
  text-shadow: 0px 0px 0.6px black;
}

.pjul ul {
  margin-top: -20px;
  width: 850px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 25px;
}

.pjli {
  background-color: white;
  border-radius: 10px;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 38px;
  padding-right: 38px;
  margin-bottom: 25px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.pjli:last-child {
  margin-bottom: 75px;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: #666;
  border-radius: 20px;
}

.row {
  width: 100%;
  height: 100px;
  background-color: #999;
  border-radius: 10px;
  margin-top: 12px;
}

.h4inner {
  margin-top: -20px;
}

.imgicon {
  filter: grayscale(100%);
  width: 5%;
  float: left;
}


@media(max-width:1200px) {
  .pjul ul {
    width: 800px !important;
    left: 250px;
  }
  .padder {
    margin-left: -25px;
  }

}

@media(max-width:1040px) {
  .pjul ul {
    width: 800px !important;
    left: 200px;
  }

}

@media(max-width:980px) {

  h4 {
    font-size: 24px !important;
  }
  i {
    font-size: 26px !important;
  }
  .padder {
    padding: 10px !important;
    margin-left: 0px;
  }

  .pjul ul {
    width: 600px !important;
  }
  .desctext {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
  }

  .innerpic {
    margin-bottom: -20px !important;
  }


}

@media(max-width:780px) {


  i {
    font-size: 22px !important;
  }
  .padder {
    padding: 5px !important;
  }

  .pjul ul {
    width: 450px !important;
  }

  .innerpic {
    margin-bottom: -100px !important;
  }

}

@media(max-width: 630px){
  .pjul ul {
    width: 360px !important;
  }
  h4 {
    font-size: 16px !important;
  }

  i {
    font-size: 18px !important;
  }

  .pjli {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .innerpic {
    margin-bottom: -130px !important;
  }

}

@media(max-width: 540px){
  .pjul ul {
    width: 360px !important;
    left: 162px;
  }

}
